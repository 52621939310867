// import {ThumbsDownIcon} from "vue-feather-icons";
// const axios = require("axios");
export default function clickAnswer(answer) {
	// console.log(this.memoryAnswersComputed);

	// console.log(this.$route.params.id);
	console.log(answer);
	const willPopupControl = this.currentPage == 1 || this.currentPage == 3;
	if (
		(answer === "1_1" && willPopupControl) ||
		this.inTransition == true ||
		this.testPaused ||
		(this.currentPage == 4 && answer != "0_0")
	)
		return;
	if (this.currentPage == 4 && answer == "0_0") {
		this.inTransition = true;
		this.exitTimelines[this.currentPage].resume();
		this.$swal({
			title: "Çözüm Ekranı Geliyor!",
			text: "Hafızanıza aldığınız ikonları bulup işaretleyiniz.",
			icon: "warning",
			showConfirmButton: true,
			confirmButtonText: "Tamam",
		}).then(() => {
			this.progressBarKey++;
			this.currentPage++;
			this.pageStartTime = Date.now();
			this.testPaused = false;
			this.pageTimeSpent = 0;
			this.animationTimelines[this.currentPage].resume();

			this.startTimer(this.config.pageTimeAllowed - 3000);
		});
		// setTimeout(() => {

		// }, 2000);
		return;
	}
	// .add(
	//   "selected-memory-answer"
	// );
	// console.log("answer ", answer);
	// console.log("index ", Math.floor((this.currentPage - 4 ) / 2));
	// console.log(
	//   "array ",
	//   this.memoryAnswers[Math.floor((this.currentPage - 4 ) / 2)]
	// );
	// console.log(
	//   this.memoryAnswers[Math.floor((this.currentPage - 4 ) / 2)].some(
	//     (ans) => ans.answer == answer
	//   )
	// );
	// console.log(this.memoryAnswers);
	// if starting test
	// if (this.currentPage == 2 && answer === 9) {
	//   console.log('START TEST')
	//   this.inTransition = true
	//   this.currentPage++
	//   console.log(this.animationTimelines)
	//   this.animationTimelines[this.currentPage].resume()
	//   this.progressBarKey++
	//   this.pageStartTime = Date.now()
	//   this.testPaused = false
	//   this.pageTimeSpent = 0
	//   this.startTimer(this.config.pageTimeAllowed)
	//   return
	// }
	if (this.currentPage >= 4) {
		if (this.memoryAnswers[0].some(ans => ans.answer == answer)) {
			console.log("clicked again");
			return;
		}
		if (answer != "0_0") {
			this.$refs[`ref${this.currentPage}${answer}`][0].classList.add(
				"selected-memory-answer",
			);
			this.$refs[`ref${this.currentPage}${answer}`][0].classList.remove(
				"clickablecard",
			);
		}
	}
	this.pageTimeSpent = Date.now() - this.pageStartTime; // keep track of time spent solving test
	// TODO save answer and time somewhere
	const calculatedPage = this.currentPage < 4 ? this.currentPage : 4;
	const answerObj = {
		page: calculatedPage,
		answer,
		time: this.pageTimeSpent - (willPopupControl ? 5000 : 2000),
	}; // create object to save to file
	// console.log(answerObj);

	// console.log(answerObj); // temporary code
	if (this.currentPage <= 3)
		this.testRecord[parseInt(this.currentPage)] = answerObj;
	else if (
		answer === "0_0" &&
		this.currentPage % 2 == 1 &&
		this.memoryAnswers[calculatedPage - 4].length != 8
	) {
		// add answer to test record
		do {
			this.memoryAnswers[calculatedPage - 4].push(answerObj);
		} while (this.memoryAnswers[calculatedPage - 4].length != 8);
		this.testRecord[calculatedPage] = this.memoryAnswers[calculatedPage - 4];
	} else if (
		this.currentPage % 2 == 1 &&
		this.memoryAnswers[calculatedPage - 4].length != 8
	) {
		this.memoryAnswers[calculatedPage - 4] = [
			...this.memoryAnswers[calculatedPage - 4],
			answerObj,
		];
		// console.log("memAnswers", this.memoryAnswers);
		if (this.memoryAnswers[calculatedPage - 4].length != 8) return;
		else
			this.testRecord[calculatedPage] = this.memoryAnswers[calculatedPage - 4];
	} else
		this.testRecord[calculatedPage] = this.memoryAnswers[calculatedPage - 4]; // add answer to test record
	this.testPaused = true;
	clearTimeout(this.testTimeout); // clear timeout for stability if test wasnt skipped
	clearTimeout(this.pageTimerTimeout);
	if (answer == "0_0") this.pageTimeLeft = 0;
	if (
		this.currentPage == this.config.lastPageNo
		// 4
	) {
		// ends test
		// console.log("pageCheck", this.currentPage, this.config.lastPageNo);
		console.dir(this.testRecord);
		let record = [];
		for (let i = 1; i <= Object.keys(this.testRecord).length; i++) {
			record.push(this.testRecord[i]);
		}
		// record = [...record, ...this.memoryAnswers];
		const testRecord = {
			id: this.$route.params.id,
			record,
		};
		// TODO !! end test logic
		this.$swal({
			title: "Test Tamamlandı, Gönderiliyor...",
			icon: "info",
			showConfirmButton: false,
			customClass: {
				confirmButton: "btn btn-info",
			},
			buttonsStyling: false,
		});
		console.dir(testRecord);
		// axios.post(`/test/${this.$route.params.id}`, testRecord).then(() => {
		localStorage.removeItem("testRecord");
		this.$swal({
			title: "İşaretlemeler Yapıldı",
			icon: "success",
			// confirmButtonText: "Tamam",
			showConfirmButton: false,
			// timer: 3000,
			// customClass: {
			// 	confirmButton: "btn btn-success",
			// },
			buttonsStyling: false,
		});
		// .then(() => {
		// 	this.$router.push("/").then(() => {
		// 		this.$router.go();
		// 	});
		// });
		// });
		return;
	}

	// if not last page
	this.inTransition = true;
	this.exitTimelines[this.currentPage].resume();
	if (
		this.currentPage < 4 ||
		(this.currentPage > 3 &&
			this.currentPage % 2 == 1 &&
			this.memoryAnswers[calculatedPage - 4].length == 8)
	) {
		if (this.currentPage >= 4) {
			// console.log("memAnswers", this.memoryAnswers);
			this.memoryAnswers[calculatedPage - 4].forEach(ans => {
				if (ans.answer != "0_0")
					this.$refs[`ref${this.currentPage}${ans.answer}`][0].classList.remove(
						"selected-memory-answer",
					);
			});
		}
		this.$swal({
			title: `${answer != "0_0" ? "İşaretleme Yapıldı" : "Süre Doldu"}`,
			icon: `${answer != "0_0" ? "success" : "warning"}`,
			showConfirmButton: false,
			timer: 2000,
		});
	}
	if (this.config.breakpoints.includes(this.currentPage)) {
		setTimeout(() => {
			this.$emit(
				"partComplete",
				this.config.breakpoints.indexOf(this.currentPage),
			); //< use this when done
		}, 2000);
		// this.$store.commit('testTutTwoMut')
	} else {
		setTimeout(() => {
			this.progressBarKey++;
			this.currentPage++;
			this.pageStartTime = Date.now();
			this.testPaused = false;
			this.pageTimeSpent = 0;
			this.animationTimelines[this.currentPage].resume();
			this.startTimer(
				willPopupControl
					? this.config.pageTimeAllowed
					: this.config.pageTimeAllowed - 3000,
			);
		}, 2000);
	}
}
