<template>
  <div class="tutorial-zone">
    <!-- <h1 class="title title-a">
      <img src="@/assets/dikoTerst2.png" alt="" width="600" />
    </h1> -->
    <!-- <transition appear name="tpopup">
          <div v-if="panel == 0" class="info info-one">
            <h1 class="title title-a">
              <img src="@/assets/dikoTerst2.png" alt="" width="600" />
            </h1>
            <div class="two-circle">
              <h1 class="title two">2</h1>
            </div>
            <h1 class="title title-b">Bölümden Oluşuyor</h1>
            <b-row>
              <b-button disabled variant="secondary" class="mr-1" @click="prevPanel()">
                <feather-icon size="2x" icon="ChevronLeftIcon"></feather-icon>
              </b-button>
              <b-button
                :variant="btnActive ? 'primary' : 'secondary'"
                :disabled="!btnActive"
                class="ml-1"
                @click="nextPanel()"
              >
                <feather-icon size="2x" icon="ChevronRightIcon"></feather-icon>
              </b-button>
            </b-row>
          </div>
        </transition> -->
    <transition appear name="tpopup">
      <div v-if="panel == 1" class="info info-two">
        <h1 class="title-a" style="font-weight: bold">4. Bölüm</h1>
        <b-badge variant="warning" pill style="font-weight: bold">
          <h1 class="title-a" style="font-weight: bold">HAFIZA</h1>
        </b-badge>
      </div>
    </transition>
    <transition appear name="tpopup">
      <div v-if="panel == 2" class="info info-three">
        <h3 class="title-c" style="font-weight: bold"> 4. Bölümde Karşılaşacağınız İkonlardan Bazıları </h3>
        <div class="test-pics">
          <div v-for="x in config.sectionTwo.cols" :key="x" class="test-columns">
            <transition-group appear @before-enter="beforeEnter" @enter="enter">
              <div v-for="y in config.sectionTwo.rows" :key="y" class="test-row" :data-index="y + x">
                <div class="card-face card-front">
                  <img :src="sectionTwoImages[parseInt(x) - 1][parseInt(y) - 1]" class="card-images" alt="Diko Test" />
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </transition>
    <transition appear name="tpopup">
      <div v-if="panel == 3" class="info info-three">
        <h3 class="title-c">Anlatım Videosu</h3>
        <div class="tut-video" style="margin-left: 80px">
          <svg-anim />
        </div>
      </div>
    </transition>
    <transition appear name="tpopup">
      <div v-if="panel == 4" class="info info-three">
        <h3 class="title-c" style="font-weight: bold">Uyarılar</h3>
        <transition-group appear tag="ul" class="tut-list" @enter="enterList">
          <h3 class="title-c" style="font-weight: bold">Uyarılar</h3>
          <!-- <ul class="tut-list"> -->
          <li key="1" class="tut-list-item" data-index="1"> Aranacak ikonları hafızanıza almanız için <span>30
              saniyeniz</span> vardır. </li>
          <li key="2" class="tut-list-item" data-index="2"> Her sayfada hafızanıza almanız gereken ikonların sayısı
            değişmektedir. </li>
          <li key="3" class="tut-list-item" data-index="3"> Hafızanıza aldığınız ikonları işaretlemeniz için <span>30
              saniyeniz</span> vardır. </li>
          <li key="4" class="tut-list-item" data-index="4"> İşaretlemelerinizi yaptıktan sonra sayfa değişecektir. </li>
          <li key="5" class="tut-list-item" data-index="5"> İşaretlemelerinizi tamamlamazsanız süre bitiminde sonraki
            sayfaya geçilecektir. </li>
          <!-- </ul> -->
        </transition-group>
      </div>
    </transition>
    <transition appear name="tpopup">
      <div v-if="panel == 5" class="info info-three">
        <!-- <div class="ready-box mb-5"> -->
        <h1 class="title title-a" style="color: #fff"> Sayfada işaretlenmiş ikonları hafızanıza alınız. </h1>
        <!-- </div> -->
        <!-- <b-button
          :variant="btnActive ? 'primary' : 'secondary'"
          :disabled="!btnActive"
          @click="$emit('tutorialTwoDone')"
        >
          Diko Test 2. Bölüm Başlat
        </b-button> -->
      </div>
    </transition>
    <!-- <b-row>
      <b-button
        :disabled="panel <= 1"
        :variant="panel <= 1 ? 'secondary' : 'primary'"
        class="mr-1"
        @click="prevPanel()"
      >
        <feather-icon size="2x" icon="ChevronLeftIcon"></feather-icon>
      </b-button>
      <b-button
        :variant="btnActive ? 'primary' : 'secondary'"
        :disabled="!btnActive"
        class="ml-1"
        @click="nextPanel()"
      >
        <feather-icon size="2x" icon="ChevronRightIcon"></feather-icon>
      </b-button>
    </b-row> -->
  </div>
</template>
<script>
import gsap from "gsap";
import SvgAnim from "./svg.vue";
import { BButton, BBadge } from "bootstrap-vue";

export default {
  components: {
    SvgAnim,
    BButton,
    BBadge,
  },
  setup() {
    const enter = (el, done) => {
      //   console.log(el.dataset.index);
      const minusDelay = el.dataset.index * 0.1;
      gsap.fromTo(
        el,
        { /*yPercent: 500,*/ opacity: 0.1 },
        {
          /*yPercent: 0,*/
          duration: 0.5,
          opacity: 1,
          delay: minusDelay,
          // ease: "Power0.easeNone",
          // onComplete: done,
          onComplete: done,
        }
      );
    };
    return {
      enter,
      enterList: (el, done) => {
        const minusDelay = el.dataset.index * 0.5;
        gsap.fromTo(
          el,
          { /*yPercent: 500,*/ opacity: 0 },
          {
            /*yPercent: 0,*/
            duration: 1.5,
            // opacity: 0,
            delay: minusDelay,
            // ease: "Power0.easeNone",
            // ease: "circ.in",
            // onComplete: done,
          }
        );
        gsap.to(el, {
          opacity: 1,
          duration: 2,
          onComplete: done,
          // ease: "Power0.easeNone",
          delay: minusDelay,
        });
      },
    };
  },
  data() {
    return {
      panel: 5,
      btnTimes: [1000, 1000, 1000, 1000, 1000, 1000], //transition ~ 1s so add on top
      config: {
        sectionTwo: {
          rows: 1,
          cols: 5,
        },
        // currentPage: parseInt(this.$route.params.page),
      },
      tutListText: [],
      sectionTwoImages: [],
    };
  },
  created() {
    let temp = [];
    for (let x = 1; x <= this.config.sectionTwo.cols; x++) {
      for (let y = 1; y <= this.config.sectionTwo.rows; y++) {
        // console.log(x,y)
        temp.push(require(`@/assets/tutorial-images/${y}-${x}-p4.svg`));
      }
      this.sectionTwoImages.push(temp);
      temp = [];
    }
  },
  mounted() {
    setTimeout(() => {
      this.$emit("btnActive");
    }, this.btnTimes[0]);
  },
  methods: {
    importAll() {
      // import section two
      // console.log(this.sectionTwoImages)
    },
    showF() {
      this.show = !this.show;
    },
    nextPanel() {
      if (this.panel == 5) {
        this.$emit("tutorialDone");
        return;
      }
      const tempPanel = this.panel + 1;
      this.panel = 99;
      setTimeout(() => {
        this.panel = tempPanel;
      }, 550);
      setTimeout(() => {
        this.$emit("btnActive");
      }, this.btnTimes[tempPanel] + 550);
    },
    prevPanel() {
      if (this.panel > 1) {
        const tempPanel = this.panel - 1;
        this.panel = 99;
        setTimeout(() => {
          this.panel = tempPanel;
        }, 550);
        setTimeout(() => {
          this.$emit("btnActive");
        }, this.btnTimes[tempPanel] + 550);
      } else {
        this.$emit("goBack");
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3.title-c {
  font-size: 2.5rem;
  line-height: 1;
  margin: 0px 5px 0px 5px;
  padding: 0px 5px 0px 5px;
  text-align: center;
}

.ready-box {
  padding: 10px;
  background: #f03a5f;
  border-radius: 20px;
}

.tut-list {
  margin: 20px;
}

.tut-list-item {
  color: #e9ecef !important;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 2rem;
}

.tut-list-item span {
  font-size: 1.5rem;

  color: #b83088;
}

.test-pics {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

img.card-images {
  /* background: #fff; */
}

.card-images {
  margin: 0.3rem;
  padding: 0.4rem;
  border-radius: 18%;
  width: 96px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  background: #fff;
}

.ico-images {
  background: #fff;
  margin: 0.3rem;
  padding: 0.4rem;
  border-radius: 18%;
  width: 128px;
}

.card-images:not(.c1_1):hover {
  cursor: pointer;
  background-color: #e9ecef;
  transform: scale(1.1);
}

.button {
  margin: 20px;
  background-color: rgb(184, 48, 136);
  color: #fff;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(1.05);
}

.title {
  color: #e9ecef !important;
}

.title-a,
.title-b {
  color: #e9ecef !important;
  margin: 20px;
  font-size: 5rem;
  text-align: center;
}

.title-c {
  color: #e9ecef !important;
  margin: 20px;
  font-size: 3rem;
}

.two {
  font-size: 8rem;
  color: #fff;
  margin-bottom: 1rem;
}

.two-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgb(184, 48, 136);

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.tpopup-enter-active {
  animation: slidein 0.5s ease;
}

.tpopup-leave-active {
  animation: slideout 0.5s ease;
}

/* .n-button-enter-active {
  animation: popupop 0.5s ease;
} */
@keyframes popupop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    /* transform: translateX(-100%); */
    transform: scale(0);
  }

  100% {
    /* transform: translateX(0); */
    transform: scale(1);
  }
}

@keyframes slideout {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.test-popup {
  padding: 1rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 999;
  width: 721.78px;
  height: 775px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

/* .test-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  max-width: 1024px;
  background: rgba(255, 255, 255, 0.3);
  border: #b83088 5px solid;
  border-radius: 50px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(3px);

  box-shadow: rgba(184, 48, 136, 0.4) -5px 5px, rgba(184, 48, 136, 0.3) -10px 10px, rgba(184, 48, 136, 0.2) -15px 15px,
    rgba(184, 48, 136, 0.1) -20px 20px, rgba(184, 48, 136, 0.05) -25px 25px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.test-popup-warning {
  color: #ff0000;
  font-weight: bold;
  font-size: 1.5rem;
}

.test-warning-lists {
  color: #e9ecef !important;
  font-size: 1.2rem;
  line-height: 2;
  padding: 10px 20px;
}

.start-test-button {
  margin-top: 20px;
}
</style>
