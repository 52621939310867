<template>
  <div class="svg-wrapper">
    <div class="bow">
      <div class="bow-wrapper">
        <svg
          id="Layer_1"
          class="svg-2"
          viewBox="0 0 180 180"
          height="400"
          width="400"
        >
          <path
            class="cls-1"
            :d="circle"
            transform="translate(-300 -350)"
          />
          <path
            class="blue-line-1"
            :d="line"
            transform="translate(-300 -350)"
          />
          <path
            class="purple-line-1"
            :d="line"
            transform="translate(-300 -350)"
          />
          <path
            class="blue-line-2"
            :d="line"
            transform="translate(-300 -350)"
          />
          <path
            class="purple-line-2"
            :d="line"
            transform="translate(-300 -350)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap'

export default {
  data() {
    return {
      //   tl: gsap.timeline({ defaults: { duration: 0.1, ease: "Back.easeOut.config(2)", opacity: 0 } }),
      circle:
        'M340.62,454.06a34.23,34.23,0,0,1,2.78-13.67,35.62,35.62,0,0,1,18.75-18.75,35.1,35.1,0,0,1,27.38,0,35.23,35.23,0,0,1,11.19,7.55,35.9,35.9,0,0,1,7.53,11.2A34.23,34.23,0,0,1,411,454.06a33.83,33.83,0,0,1-2.78,13.63,35.67,35.67,0,0,1-7.53,11.15,36.11,36.11,0,0,1-11.19,7.5,35.1,35.1,0,0,1-27.38,0,36.62,36.62,0,0,1-11.17-7.5,35.48,35.48,0,0,1-7.58-11.15,33.83,33.83,0,0,1-2.78-13.63Zm15.94,0a18.36,18.36,0,0,0,1.53,7.43,19.29,19.29,0,0,0,4.13,6.05,19.92,19.92,0,0,0,6.1,4.11,18.48,18.48,0,0,0,7.48,1.5,19,19,0,0,0,7.58-1.5,19.78,19.78,0,0,0,6.12-4.11,19.07,19.07,0,0,0,4.11-6.05,18.67,18.67,0,0,0,1.48-7.43,18.34,18.34,0,0,0-1.53-7.41A19.21,19.21,0,0,0,375.8,435.1a19.15,19.15,0,0,0-7.45,1.47,19.09,19.09,0,0,0-10.26,10.11,18.34,18.34,0,0,0-1.53,7.41Z',
      line: 'M443.5,454.06A66.33,66.33,0,0,1,438,480.64a67.55,67.55,0,0,1-35.65,35.52,66.74,66.74,0,0,1-26.55,5.43V505.35a50.65,50.65,0,0,0,20.13-4.1,51.33,51.33,0,0,0,27.18-27.08,50.17,50.17,0,0,0,4.13-20.11Z',
    }
  },
  setup() {},
  created() {},
  mounted() {
    // svg anim
    gsap
      .timeline({
        repeat: -1,
        defaults: {
          rotate: 540,
          transformOrigin: '0px 0px',
          duration: 3,
          ease: 'linear',
        },
      })
      .to('.blue-line-1', {})
      .to('.purple-line-1', {}, '<.5')
      .to('.blue-line-2', {}, '<.5')
      .to('.purple-line-2', {}, '<.5')
      .to('.blue-line-1', { rotate: 0 }, '<3')
      .to('.purple-line-1', { rotate: 0 }, '<.5')
      .to('.blue-line-2', { rotate: 0 }, '<.5')
      .to('.purple-line-2', { rotate: 0 }, '<.5')
    // end of loading svg anim
  },
}
</script>
<style scoped>
.bow {
  /* width: 100%;
  height: 100%; */
}
/* .bow-wrapper {
  margin-left: 100px;
} */
.circle {
  width: 30%;
  height: 30%;
}
.svg-2 {
  /* padding-top: -50px; */
  display: flex;
}
.blue-line-1,
.blue-line-2 {
  z-index: 5;
  fill: var(--diko-primary-color);
  fill-rule: evenodd;
}
.purple-line-1,
.purple-line-2 {
  z-index: 5;
  fill: var(--diko-secondary-color);
  fill-rule: evenodd;
}
.cls-1 {
  padding-left: 50%;
  padding-top: 50%;
  fill: var(--diko-secondary-color);
  fill-rule: evenodd;
}
/* .svg-wrapper {
  position: relative;
} */
</style>
