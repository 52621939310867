import gsap from "gsap";

export default function exitAnimation(page) {
  const tl = gsap.timeline({
    paused: true,
    defaults: {
      //   padding: "60px",
      duration: 0.7,
      // yPercent: 500,
      // xPercent: 888,
      opacity: 0,
    },
  });
  for (let i = this.config.cols; i > 0; i--) {
    for (let j = this.config.rows; j > 0; j--) {
      //   let delay = (i + j) * 0.1;
      //   gsap.set(`#id${page}_${i}_${j}`, { opacity: 0 });
      tl.to(`#id${page}_${j}_${i}`, {}, `${(9 - (i + j)) / 10}`);
    }
  }
  this.exitTimelines[page] = tl;
}
