import importAll from "./importAll";
import setupTimeline from "./setupTimeline";
import startTimer from "./startTimer";
import pauseTest from "./pauseTest";
import resumeTest from "./resumeTest";
import clickAnswer from "./clickAnswer";
import exitAnimation from "./exitAnimation";
import pageTimer from "./pageTimer";

export default {
	importAll,
	setupTimeline,
	startTimer,
	pauseTest,
	resumeTest,
	clickAnswer,
	exitAnimation,
	pageTimer,
};
