export default function startTimer(time) {
	const willPopupControl = this.currentPage == 1 || this.currentPage == 3;
	this.pageStartTime = Date.now();
	this.pageTimeLeft = Math.round(
		(time > 15000 ? time - (willPopupControl ? 5000 : 2000) : time) / 1000,
	);
	setTimeout(() => {
		this.inTransition = false;
		this.pageTimer();
	}, willPopupControl ? 5000 : 2000);
	this.testTimeout = setTimeout(() => {
		this.clickAnswer("0_0"); // skip test if timer runs out
	}, time);
}
