var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-page"},[_vm._m(0),_c('div',{staticClass:"test-app-wrapper-outer"},[_c('div',{staticClass:"test-app-wrapper-inner"},[_c('transition-group',{attrs:{"appear":"","name":"tpopup"}},[(_vm.headerActive)?_c('h1',{key:"wImg",staticClass:"title title-a"},[_c('img',{attrs:{"src":require("@/assets/dikogbtlogo.svg"),"alt":"","width":"600"}})]):_c('h1',{key:"wOimg",staticClass:"title title-a"})]),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[(_vm.welcomeActive)?_c('test-welcome',{ref:"welcome",on:{"btnActive":function($event){return _vm.activateButton()},"welcomePassed":function($event){return _vm.welcomePassed()},"headerActivate":function($event){_vm.headerActive = true},"footerActivate":function($event){_vm.footerActive = true}}}):_vm._e()],1),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[(_vm.intros[0] == true)?_c('test-tutorials',{key:_vm.tutKeys[0],ref:"tut0",on:{"tutorialDone":function($event){return _vm.tutorialDone(0)},"goBack":function($event){return _vm.goBack()},"refresh":function($event){return _vm.refresh(0)},"btnActive":function($event){return _vm.activateButton()}}}):_vm._e()],1),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[_c('test-questions',{directives:[{name:"show",rawName:"v-show",value:(_vm.testAreaActive),expression:"testAreaActive"}],ref:"questionarea",attrs:{"idk":_vm.idk},on:{"partComplete":_vm.partComplete}})],1),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[(_vm.intros[1])?_c('test-tutorials-part-two',{key:_vm.tutKeys[1],ref:"tut1",on:{"tutorialDone":function($event){return _vm.tutorialDone(1)},"refresh":function($event){_vm.activePanel = 0;
            _vm.tutKeys[1]++;},"btnActive":function($event){return _vm.activateButton()}}}):_vm._e()],1),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[(_vm.intros[2])?_c('test-tutorials-part-three',{key:_vm.tutKeys[2],ref:"tut2",on:{"tutorialDone":function($event){return _vm.tutorialDone(2)},"refresh":function($event){_vm.activePanel = 0;
            _vm.tutKeys[2]++;},"btnActive":function($event){return _vm.activateButton()}}}):_vm._e()],1),_c('transition',{attrs:{"appear":"","name":"tpopup"}},[(_vm.intros[3])?_c('test-tutorials-part-four',{key:_vm.tutKeys[3],ref:"tut3",on:{"tutorialDone":function($event){return _vm.tutorialDone(3)},"refresh":function($event){_vm.activePanel = 0;
            _vm.intros[3] = false;
            _vm.setTimeout(function () {
              _vm.intros[3] = true;
            }, 500);},"btnActive":function($event){return _vm.activateButton()}}}):_vm._e()],1),_c('transition-group',{staticStyle:{"display":"flex","width":"100%","flex-direction":"column","flex-wrap":"nowrap","align-items":"center"},attrs:{"appear":"","name":"tpopup"}},[(_vm.welcomeActive && _vm.activePanel == 2)?_c('b-row',{key:"line"},[_c('span',{staticStyle:{"height":"10px","width":"500px","background-color":"#f205cb","border-radius":"20px","margin-bottom":"20px"}})]):_vm._e(),(_vm.welcomeActive && _vm.activePanel == 2)?_c('b-row',{key:"gbtheader"},[_c('h1',{staticStyle:{"margin-right":"25px","margin-left":"25px"}},[_vm._v("GRUP BELİRLEME TESTİ")])]):_vm._e(),(_vm.footerActive)?_c('b-row',{key:"btnarea"},[(_vm.intros.some(function (e) { return e == true; }) && _vm.activePanel >= 1)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":/*intros[0] ? 'primary' :*/
              !_vm.welcomeActive && _vm.activePanel > 0 ? 'primary' : 'secondary',"disabled":_vm.welcomeActive || _vm.activePanel == 0},on:{"click":function($event){return _vm.prevPanel()}}},[_c('feather-icon',{attrs:{"size":"2x","icon":"ChevronLeftIcon"}})],1):_vm._e(),_c('b-button',{staticClass:"ml-1",attrs:{"variant":_vm.buttonActive ? 'primary' : 'secondary',"disabled":!_vm.buttonActive},on:{"click":function($event){return _vm.nextPanel()}}},[(_vm.welcomeActive || _vm.intros[3] ? _vm.activePanel < 4 : _vm.activePanel < 3)?_c('feather-icon',{attrs:{"size":"2x","icon":"ChevronRightIcon"}}):_c('span',[_vm._v("Test'e "+_vm._s(_vm.intros[0] ? "Başla" : "Devam Et"))])],1)],1):_vm._e()],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stars"},[_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"}),_c('div',{staticClass:"star"})])}]

export { render, staticRenderFns }