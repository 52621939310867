export default {
	config: {
		rows: 5,
		cols: 5,
		pageTimeAllowed: 20000, // 15 seconds
		lastPageNo: 5,
		breakpoints: [1, 2, 3],
	},
	pageTimeLeft: 30,
	pageTimerTimeout: null,
	pageTimeSpent: 0,
	pageStartTime: 0,
	testTimeOut: "",
	testPaused: true,
	testImages: [],
	testRecord: [],
	currentPage: 1,
	inTransition: true,
	animationTimelines: [],
	exitTimelines: [],
	progressBarKey: 0,
	memoryAnswers: [[], [], [], [], []],
	panelMountedOnce: false,
};
