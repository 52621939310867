<template>
  <KeepAlive>
    <!-- <div id="stars-group-1" />
      <div id="stars-group-2" />
      <div id="stars-group-3" />
      <div id="stars-group-4" />
      <div id="stars-group-5" />
      <div id="stars-group-6" />
      <div class="stars">
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
        <div class="star" />
      </div> -->
    <transition appear name="tpopup">
      <div class="test-area">
        <div class="pbar">
          <div class="time-buttons-area">
            <b-button v-if="!testPaused && !inTransition" variant="outline-primary" @click="pauseTest()">
              <!-- class="button is-small is-danger time-buttons btn-pause" -->
              <!-- class="button is-small is-danger time-buttons btn-resume" -->
              <feather-icon size="2x" icon="PauseIcon"></feather-icon>
            </b-button>
            <b-button v-if="testPaused || inTransition" variant="outline-info" @click="resumeTest()"
              :disabled="inTransition">
              <feather-icon size="2x" icon="PlayIcon"></feather-icon>
            </b-button>
          </div>
          <div class="progress-bar-area">
            <progress-bar :pausedState="testPausedComputed" :transition="inTransition" :key="progressBarKey"
              :example="true" />
          </div>
          <div class="time-buttons-area">
            <b-button style="width: 40px !important" v-if="testPaused && !inTransition" variant="outline-primary"
              v-b-tooltip.hover title="Henüz Çalışmıyor">
              {{ testTimeComputed }}
              <!-- <feather-icon icon="FastForwardIcon"/> -->
            </b-button>
            <b-button style="width: 40px !important" disabled v-else variant="outline-primary">
              {{ testTimeComputed }}
            </b-button>
          </div>
        </div>
        <div v-for="page in testImages.length" v-show="page == currentPage /*&& (!testPaused || inTransition)*/"
          :class="{ dontshow: testPaused && !inTransition }" :id="'page-' + page" :key="page" class="test-page">
          <!-- :class="{'controlClickable': page<= 5 || (page >= 11 && page <=15)}" -->
          <!-- <div class="test-columns"  > -->
          <!-- <div class="test-row" > -->
          <transition-group v-for="x in config.rows" :key="x">
            <img v-for="y in config.cols" :id="`id${page}_${x}_${y}`" :key="y" :data-index="y + x"
              :src="testImages[page - 1][parseInt(x) - 1][parseInt(y) - 1]" class="card-images" :class="{
                  clickablecard:
                    (page <= 5 && (x != 1 || y != 1)) ||
                    (page >= 6 && page <= 10) ||
                    (page >= 11 && page <= 15 && (x != 1 || y != 1)) ||
                    (page > 15 && page % 2 == 1),
                }" alt="Diko Test" @click="clickAnswer(`${x}_${y}`)" :ref="`ref${page}${x}_${y}`" />
          </transition-group>
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
      <!-- <div v-show="this.$store.state.testTutTwo" class="part-two-tut">
          <test-tutorials-part-two @tutorial-end="clickAnswer(9)" />
        </div> -->
      <!-- <div class="svg-anim">
        <svg-component></svg-component>
      </div> -->
    </transition>
  </KeepAlive>
</template>
<script>
//  import svgComponent from "./svg.vue";
import ProgressBar from "./ProgressBar.vue";
import testData from "../exampleScripts/data.js";
import testMethods from "../exampleScripts/methods.js";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { BButton, VBTooltip } from "bootstrap-vue";

export default {
  components: { ProgressBar, FeatherIcon, BButton },
  data() {
    return testData;
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    testPausedComputed() {
      return this.testPaused;
    },
    testTimeComputed() {
      return Math.round(this.pageTimeLeft);
    },
  },

  created() {
    this.testRecord = JSON.parse(localStorage.getItem("testRecord")) || {};
    // this.$store.commit('hideHeaderMut')
    this.importAll();
  },
  mounted() {
    for (let i = this.currentPage || 1; i <= this.config.lastPageNo; i++) {
      this.setupTimeline(i);
      this.exitAnimation(i);
    }
  },
  methods: testMethods,
  props: {
    idk: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    idk(newVal, oldVal) {
      console.log("idk changed", newVal, oldVal);
      // console.log(newVal,oldVal);
      // if(this.panelMountedOnce) {
      // this.progressBarKey++;
      // this.currentPage++;
      if (newVal != 0) {
        // this.startTimer(this.config.pageTimeAllowed);
        if (newVal == 2 || newVal == 3 || newVal == 4) this.currentPage++;
        this.pageStartTime = Date.now();
        this.testPaused = false;
        this.pageTimeSpent = 0;
        this.animationTimelines[this.currentPage].resume();
        this.startTimer(
          this.currentPage == 1 || this.currentPage == 3
            ? this.config.pageTimeAllowed
            : this.config.pageTimeAllowed - 3000
        );
      }
      // } else {
      //   if(this.currentPage!=0)
      //     this.animationTimelines[this.currentPage].resume();
      //   setTimeout(() => {
      //     this.inTransition=false;
      //   },5000);
      //   this.panelMountedOnce=true;
      // }
    },
  },
  setup() {},
};
</script>
<style scope lang="scss">
@import "../stylesheets/testArea.css";

.time-btn {
  border-radius: 50% !important;
  padding: 10px !important;
}

.selected-memory-answer {
  border: 2px solid #b83088;
  border-radius: 100%;
  padding: 5px;
  opacity: 0.4 !important;
  filter: alpha(opacity=40) !important;
}

.dontshow {
  opacity: 0 !important;
}
</style>
