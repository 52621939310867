import gsap from "gsap";
// const gsap = require("gsap");
export default function setupTimeline(page) {
	const tl = gsap.timeline({
		paused: this.page != 1,
	});
	const willPopupControl = page == 1 || page == 3;
	if (willPopupControl) {
		tl
			.to(
				`#id${page}_1_1`,
				{
					duration: 0.5,
					opacity: 1,
					xPercent: 215,
					yPercent: 215,
					scale: 5,
				},
				"controlBig",
			)
			.to(
				`#id${page}_1_1`,
				{
					duration: 0.5,
					xPercent: 0,
					yPercent: 0,
					scale: 1,
				},
				"controlBig+=3",
			);
	}
	for (let i = 1; i <= this.config.cols; i++) {
		for (let j = 1; j <= this.config.rows; j++) {
			const delay = (i + j) * 0.1;
			if (i == 1 && j == 1 && willPopupControl) continue;
			// console.log(i, j)
			gsap.set(`#id${page}_${j}_${i}`, { opacity: 0 });
			tl.fromTo(
				`#id${page}_${j}_${i}`,
				{ /*yPercent: 500, xPercent: 888,*/ opacity: 0 },
				{
					yPercent: 0,
					xPercent: 0,
					duration: 0.5,
					opacity: 1,
				},
				`controlBig+=${willPopupControl ? 3 + delay : delay}`,
			);
		}
	}
	// console.log(page, tl)
	this.animationTimelines[page] = tl;
}
