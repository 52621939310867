<template>
  <div class="test-page">
    <div class="stars">
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
      <div class="star" />
    </div>
    <!-- <div class="start-msg"> -->
    <div class="test-app-wrapper-outer">
      <div class="test-app-wrapper-inner">
        <transition-group appear name="tpopup">
          <h1 key="wImg" class="title title-a" v-if="headerActive">
            <img src="@/assets/dikogbtlogo.svg" alt="" width="600" />
          </h1>
          <h1 key="wOimg" class="title title-a" v-else>
            <!-- <img  src="@/assets/dikoTerst2.png" alt="" width="600" /> -->
          </h1>
        </transition-group>
        <transition appear name="tpopup">
          <test-welcome
            v-if="welcomeActive"
            @btnActive="activateButton()"
            @welcomePassed="welcomePassed()"
            @headerActivate="headerActive = true"
            @footerActivate="footerActive = true"
            ref="welcome"
          />
        </transition>
        <!-- </div> -->
        <!-- <div class="test-intr"> -->
        <transition appear name="tpopup">
          <test-tutorials
            v-if="intros[0] == true"
            @tutorialDone="tutorialDone(0)"
            @goBack="goBack()"
            @refresh="refresh(0)"
            @btnActive="activateButton()"
            :key="tutKeys[0]"
            ref="tut0"
          />
        </transition>
        <!-- </div> -->
        <!-- <div class="test-area"> -->
        <transition appear name="tpopup">
          <test-questions
            :idk="idk"
            v-show="testAreaActive"
            ref="questionarea"
            @partComplete="partComplete"
          />
        </transition>
        <!-- </div> -->
        <!-- <div class="test-intr"> -->
        <transition appear name="tpopup">
          <test-tutorials-part-two
            v-if="intros[1]"
            @tutorialDone="tutorialDone(1)"
            @refresh="
              activePanel = 0;
              tutKeys[1]++;
            "
            @btnActive="activateButton()"
            :key="tutKeys[1]"
            ref="tut1"
          />
        </transition>
        <transition appear name="tpopup">
          <test-tutorials-part-three
            v-if="intros[2]"
            @tutorialDone="tutorialDone(2)"
            @refresh="
              activePanel = 0;
              tutKeys[2]++;
            "
            @btnActive="activateButton()"
            :key="tutKeys[2]"
            ref="tut2"
          />
        </transition>
        <transition appear name="tpopup">
          <test-tutorials-part-four
            v-if="intros[3]"
            @tutorialDone="tutorialDone(3)"
            @refresh="
              activePanel = 0;
              intros[3] = false;
              setTimeout(() => {
                intros[3] = true;
              }, 500);
            "
            @btnActive="activateButton()"
            :key="tutKeys[3]"
            ref="tut3"
          />
        </transition>
        <!-- </div> -->
        <!-- <div class="test-finish"> -->
        <!-- <test-finish v-if="!testAreaActive && introOnePassed && introTwoPassed && testStarted" -->
        <!-- </div> -->
        <transition-group
          appear
          name="tpopup"
          style="
            display: flex;
            width: 100%;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
          "
        >
          <b-row key="line" v-if="welcomeActive && activePanel == 2">
            <span
              style="
                height: 10px;
                width: 500px;
                background-color: #f205cb;
                border-radius: 20px;
                margin-bottom: 20px;
              "
            ></span>
          </b-row>
          <b-row key="gbtheader" v-if="welcomeActive && activePanel == 2">
            <h1 style="margin-right: 25px; margin-left: 25px">GRUP BELİRLEME TESTİ</h1>
          </b-row>
          <b-row v-if="footerActive" key="btnarea">
            <b-button
              v-if="intros.some((e) => e == true) && activePanel >= 1"
              :variant="
                /*intros[0] ? 'primary' :*/
                !welcomeActive && activePanel > 0 ? 'primary' : 'secondary'
              "
              :disabled="welcomeActive || activePanel == 0"
              class="mr-1"
              @click="prevPanel()"
            >
              <feather-icon size="2x" icon="ChevronLeftIcon"></feather-icon>
            </b-button>
            <b-button
              :variant="buttonActive ? 'primary' : 'secondary'"
              :disabled="!buttonActive"
              class="ml-1"
              @click="nextPanel()"
            >
              <feather-icon
                v-if="welcomeActive || intros[3] ? activePanel < 4 : activePanel < 3"
                size="2x"
                icon="ChevronRightIcon"
              >
              </feather-icon>
              <span v-else>Test'e {{ intros[0] ? "Başla" : "Devam Et" }}</span>
            </b-button>
          </b-row>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import TestWelcome from "./components/TestWelcome.vue";
import TestTutorials from "./components/example/TestTutorials.vue";
import TestQuestions from "./components/ExampleTestQuestions.vue";
import TestTutorialsPartTwo from "./components/example/TestTutorialsPartTwo.vue";
import TestTutorialsPartThree from "./components/example/TestTutorialsPartThree.vue";
import TestTutorialsPartFour from "./components/example/TestTutorialsPartFour.vue";
import { BButton, BRow } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
/*
import test part 1
import tutorial part 2
import test part 2
import finish page ? 
*/
export default {
  components: {
    TestTutorials,
    TestWelcome,
    TestTutorialsPartTwo,
    TestTutorialsPartThree,
    TestTutorialsPartFour,
    TestQuestions,
    BButton,
    BRow,
    FeatherIcon,
  },
  data() {
    return {
      welcomeActive: true,
      questionAreaActive: false,
      intros: [false, false, false, false],
      testAreaActive: false,
      headerActive: false,
      footerActive: false,
      tutKeys: [0, 0, 0, 0],
      idk: 0,
      activePanel: 0,
      buttonActive: false,
    };
  },

  created() {
    // this.$store.commit("hideHeaderMut");
  },
  mounted() {
    setTimeout(() => {
      this.nextPanel();
      setTimeout(() => {
        this.nextPanel();
        setTimeout(() => {
          this.nextPanel();
          setTimeout(() => {
            this.nextPanel();
          }, 2000);
        }, 2000);
      }, 2000);
    }, 2000);
  },
  methods: require("./testPageMethods.js"),
};
</script>
<style scoped lang="scss">
@import "./stylesheets/stars.scss";

.tpopup-enter-active {
  animation: slidein 0.5s ease;
}

.tpopup-leave-active {
  animation: slideout 0.5s ease;
}

.test-app-wrapper-outer {
  position: absolute;
  /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
  overflow: hidden;
  font-size: 2em;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* padding: 5%; */
  height: 100vh;
  z-index: 999;
  flex-direction: column;
  background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
}

.test-app-wrapper-inner {
  padding: 1rem;
  border-radius: 20px;
  background-image: url("../../assets/images/diko-arkaplan.png");
  z-index: 999;
  width: 721.78px;
  height: 775px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
}

@keyframes slidein {
  0% {
    /* transform: translateX(-100%); */
    transform: scale(0);
  }

  100% {
    /* transform: translateX(0); */
    transform: scale(1);
  }
}

@keyframes slideout {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
</style>
